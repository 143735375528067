/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, FullmapCover, Image, List, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"ivatomsvatba"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1643y42 js-anim  --anim7 --anim-s5 --full" style={{"paddingBottom":0,"marginBottom":0}} anim={"7"} name={"uvod"} animS={"5"} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/cc0ec9ff57e94b6dba3011e6f2a49220_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 pt--12" style={{"maxWidth":1080}} anim={"6"} animS={"5"}>
              
              <Title className="title-box title-box--shadow5 fs--128 w--300 ls--08 lh--14" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Ivka &amp; Tom</span>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--shadow5 fs--62 mt--10" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">se budou brát</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25" style={{"paddingTop":93}} name={"informace"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pl--0 pr--0 flex--center" anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--shape2" style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" content={"Obřad"}>
              </Title>

              <Text className="text-box" style={{"marginTop":17}} content={"19.10.2024"}>
              </Text>

              <Text className="text-box" content={"11:00<br>"}>
              </Text>

              <Text className="text-box" content={"Kostel Nanebevzetí Panny Marie"}>
              </Text>

              <Text className="text-box" content={"Neratov<br>517 61 Bartošovice v Orlických horách<br><br>"}>
              </Text>

              <FullmapCover style={{"marginTop":0}} place={"Neratov kostel"} zoom={"15"}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image className="--shape2" src={"https://cdn.swbpg.com/t/39375/639ce5737bd446f4b0bc3e9cc5ebbfd1_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39375/639ce5737bd446f4b0bc3e9cc5ebbfd1_s=350x_.png 350w, https://cdn.swbpg.com/t/39375/639ce5737bd446f4b0bc3e9cc5ebbfd1_s=660x_.png 660w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":49}} name={"informace-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 flex--center" style={{"maxWidth":""}} anim={"2"} animS={"3"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image src={"https://cdn.swbpg.com/t/39375/1812111514cb42688fcb0f2d0b7cd801_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/39375/1812111514cb42688fcb0f2d0b7cd801_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/39375/1812111514cb42688fcb0f2d0b7cd801_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/39375/1812111514cb42688fcb0f2d0b7cd801_s=860x_.jpeg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--style8 title-box--center w--300" content={"Svatební hostina"}>
              </Title>

              <Text className="text-box" style={{"marginTop":27}} content={"Společenský dům U Proroka<br>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<a href=\"https://uproroka.cz/\">https://uproroka.cz/</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":101}} layout={"l13"} name={"cpminn54hlb"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":600}}>
              
              <Title className="title-box title-box--style5 title-box--center ls--0 lh--1" style={{"maxWidth":638,"paddingRight":0,"paddingLeft":0}} content={"Harmonogram"}>
              </Title>

              <Text className="text-box text-box--center" style={{"marginTop":24}} content={"<span style=\"font-weight: bold;\">Pátek 18.10.2024</span>"}>
              </Text>

              <List className="text-box text-box--left" style={{"marginTop":0}} subtype={"unordered"} content={"<li>Od 17:00 Večeře - guláš v místní hospodě</li><li>Posezení venku nebo ve společenské místnosti Chaty Neratov a Hanuška</li>"}>
              </List>

              <Text className="text-box text-box--center" content={"<span style=\"font-weight: bold;\">Sobota 19.10.2024</span>"}>
              </Text>

              <List className="text-box text-box--left" style={{"marginTop":0,"maxWidth":588,"paddingRight":0,"paddingLeft":0}} subtype={"unordered"} content={"<li>8:00 - 10:30 Snídaně a občerstvení - Sdružení Neratov</li><li>11:00 Svatební obřad</li><li>12:30 Společné foto</li><li>13:00 Svatební oběd</li><li>14:30 Krájení dortu</li><li>15:00 Srdce hřejivé hudební vystoupení kamarádů Lucky a Chrise</li><li>15:00 Focení s novomanžely</li><li>16:30 Raut</li><li>17:00 První tanec</li><li>18:00 Vystoupení kapely <a href=\"https://www.atelierskupina.cz/\" style=\"text-decoration-line: underline;\">Ateliér</a></li><li>19:00 Večerní raut</li><li>Volná zábava - DJ Gianni B</li>"}>
              </List>

              <Text className="text-box text-box--center" content={"<span style=\"font-weight: 700;\">Neděle 20.10.2024</span><br>"}>
              </Text>

              <List className="text-box text-box--left" style={{"marginTop":0}} subtype={"unordered"} content={"<li>8:00 - 10:00 Snídaně a občerstvení - Sdružení Neratov</li>"}>
              </List>

              <Text className="text-box text-box--center" content={"<span style=\"font-style: italic;\">Časový plán se může mírně lišit podle aktuálních potřeb a počasí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39375/fa61e43ff4be48e3b523edc74d41623c_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/39375/fa61e43ff4be48e3b523edc74d41623c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39375/fa61e43ff4be48e3b523edc74d41623c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39375/fa61e43ff4be48e3b523edc74d41623c_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column layout={"l13"} style={{"paddingBottom":88,"paddingTop":0}} name={"w4ntc29cocb"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box title-box--style5 title-box--center ls--0 lh--1" style={{"maxWidth":1000,"paddingRight":0,"paddingLeft":0}} content={"Ubytování"}>
              </Title>

              <Text className="text-box" style={{"marginTop":22}} content={"Máme rezervované ubytování s kapacitou 132 osob na celý víkend (PÁ-NE) s tím, že nocleh ze soboty na neděli bude hrazený.<br>Prosíme o sdělení kdy plánujete příjezd ať můžeme doladit ubytování všech svatebčanů.<br>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"font-weight: bold;\">Ubytovací možnosti zahrnují</span>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<a href=\"https://uproroka.cz/\">Společenský dům U Proroka</a>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<a href=\"https://www.chataneratov.cz/\">Chata Neratov</a>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"<a href=\"http://www.chata-hanuska.cz/\">Chata Hanuška</a>"}>
              </Text>

              <Text className="text-box" style={{"marginTop":0}} content={"Poutní dům"}>
              </Text>

              <Text className="text-box" style={{"marginTop":14}} content={"Jedná se vesměs o horské chaty a s tím souvisejícími ubytovacími službami. Budeme se snažit přiřadit pokoje tak, aby každý měl dostatek soukromí. Z vaší strany prosíme o shovívavost, bude nás přece jenom hodně. :-)"}>
              </Text>

              <Text className="text-box" style={{"marginTop":12}} content={"Pro ty kdo si přejí pojmout svatební víkend dobrodružněji, je vedle kostela možnost stanování včetně sociálního zařízení. :-)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39375/4c6f5097ffbe4268906d987354ea33bd_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} RootClassName={"column__pic"} srcSet={"https://cdn.swbpg.com/t/39375/4c6f5097ffbe4268906d987354ea33bd_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0}} name={"ate2rijhdn"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"Svatební dary"}>
              </Title>

              <Text className="text-box text-box--center pt--08" style={{"maxWidth":467,"marginTop":0}} content={"Darem pro nás bude vaše ochota přijet až do Neratova a být s námi ve svatební den. Pokud byste nás přece jenom chtěli něčím obdarovat, jakýkoliv dobrovolný příspěvek přijde vhod."}>
              </Text>

              <Text className="text-box text-box--center" style={{"marginTop":23}} content={"Kytice si z praktického důvodu nepřejeme, takže se nemusíte starat jak je dovezete v rozumném stavu až do Neratova. :-)&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingBottom":16}}>
              
              <Title className="title-box title-box--center" content={"Neratov"}>
              </Title>

              <Text className="text-box text-box--center pt--08" style={{"marginTop":0}} content={"Ptáte se proč zrovna Neratov?<br>Ivku chytl Neratov na čundru u srdce. :-)<br>Doporučujeme si poslechnout pěkná slova o vzniku osady Neratov přímo od místního faráře Josefa Suchára."}>
              </Text>

              <Text className="text-box text-box--center" style={{"maxWidth":467,"marginTop":16,"marginBottom":0,"paddingBottom":59}} content={"<script>if(typeof(window.jsScriptOutputted)==\"undefined\"){var jsScriptOutputted=true;var jsScript=document.createElement(\"script\");jsScript.type=\"text/javascript\";jsScript.src=\"https://vltava.rozhlas.cz/sites/all/libraries/responsive-external-embeds/cro_responsiveexternalembeds.min.js\";jsScript.onload=function(){};document.head.appendChild(jsScript);}</script><iframe name=\"embed-8968062\" class=\"cro-embed__parent\" frameborder=\"0\" scrolling=\"no\" allowfullscreen=\"\" src=\"https://radiozurnal.rozhlas.cz/cro_soundmanager/files/8968062/field_main_audio\"></iframe><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"Dress code"}>
              </Title>

              <Text className="text-box text-box--center pt--08" style={{"maxWidth":467,"marginTop":0}} content={"Žádný není! Hlavně ať se cítíte pohodlně. :-)<br>Nezapomeňte přibalit teplé oblečení!<br>"}>
              </Text>

              <Title className="title-box title-box--center" content={"Doprava"}>
              </Title>

              <Text className="text-box" style={{"marginTop":17}} content={"V případě, že se nemáte na svatbu jak dopravit, tak se nám ozvěte a my to za vás vymyslíme. :-)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":16,"paddingBottom":0,"marginBottom":52}} name={"sqj4e21pddd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0,"marginBottom":0}}>
              
              <Title className="title-box title-box--style5 title-box--center ls--0 lh--1" style={{"maxWidth":1000,"paddingRight":0,"paddingLeft":0}} content={"POTVRZENÍ ÚČASTI"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650,"marginTop":13}} content={"<span style=\"font-weight: bold;\">Prosíme všechny zvané hosty o potvrzení účasti vyplněním krátkého formuláře. Velmi nám to pomůže s následnou organizací. Děkujeme!</span>"}>
              </Text>

              <ContactForm className="ff--1" action={"contact"} name={"xghp0q0irqi"} style={{"paddingBottom":0,"paddingRight":0}} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno","id":"8ighhr6njwl"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail","id":"cpk2dpnoijk"},{"name":"Počet osob","type":"text","placeholder":"Celkový počet osob","id":"h0q29e5spo","required":true},{"id":"czefk7wlj142","type":"text","name":"Příjezd","placeholder":"Přijedete již v pátek nebo až v sobotu? Využijete nabízené ubytovací služby?"},{"id":"lae0s63vduek","type":"text","placeholder":"Specifikujte počet dospělých a dětí. Je potřeba zajistit dietní požadavky (veg., bezlepek., apod.)?","name":"Poznámka"},{"name":"Odeslat","type":"submit","id":"vjbeglrw4zk","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-6mdrg5 --center pb--80 pt--80" name={"informace-5"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39375/775e4152cdd444d097ce7612df5019a8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mt--80">
              
              <Title className="title-box title-box--shadow5 title-box--center fs--86" content={"<span style='color: white'>Těšíme se na Vás!</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mb--80 mt--40" style={{"maxWidth":900}} columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}